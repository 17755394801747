//Menu item

.sf-menu.sf-style-white {
  > li.sf-item-8 {
    span {
      background-image: url('../img/reactiva.png');
      background-image: url('../img/reactiva.svg');

      &:hover {
        background-image: url('../img/reactiva.png');
        background-image: url('../img/reactiva.svg');
      }
    }

    ul {
      min-width: 100%;
    }
  }
}


//REACTIVA CONTENT

.node-reactiva {
  .field-name-field-gobierno {
    margin-top: 20px;
  }

  .field-name-field-gobierno, .field-name-field-generalitat, .field-name-field-ayuntamiento {
    padding-bottom: 20px;

    .field-label {
      margin-bottom: 20px;
      padding-left: 30px;
      color: $primary-shade;
      font-size: 21px;
      font-weight: 600;
      text-transform: uppercase;
      @media (max-width: 1024px) {
        padding-left: 0;
      }
      @media (max-width: 640px) {
        font-size:20px;
      }
    }

    .field-items {
      ul {
        padding-left: 30px;
        @media (max-width: 1024px) {
          padding-left: 0;
        }

        li {
          position: relative;
          padding-left: 20px;

          p {
            padding-left: 0 !important;
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            width: 8px;
            height: 8px;
            background-color: $primary-shade;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
          }

          ul {
            li {
              &:before {
                top: 8px;
                background-color: $secondary-shade;
                width: 6px;
                height: 6px;
              }

              ul {
                li {
                  &:before {
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .field-name-body {
    margin-top: 30px;
  }
}


//BLOQUE COVID19

.info-covid {
  margin: 20px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-image: url('../img/valenciaactiva-image.png');
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: $bg;
  @media (max-width: 640px) {
    background-image: none;
    border:2px solid $primary-shade;
  }
  .text-group {
    max-width: 60%;
    padding: 15px;
    background-color: $bg;
    @media (max-width: 640px) {
      max-width: 100%;
    }
    .text {
      margin-top: 0;
      padding-left: 0 !important;
      font-size: 13px;

      span {
        a {
          pointer-events: none;
          color: $text;
          font-weight: normal;
        }
      }

      strong {
        font-weight: 600;
      }

      &.center {
        text-align: center;
      }

      &.certificado {
        margin-bottom: 2px;
        margin-left: 20px;
        color: $black;
        padding-left: 20px !important;
        background-image: url('../img/id-card.svg');
        background-repeat: no-repeat;
        -webkit-background-size: 15px 15px;
        background-size: 15px 15px;
        background-position: left center;
        font-weight: 600;
        @media (max-width: 640px) {
          margin-left: 0;
        }
      }

      &.clave {
        margin-left: 20px;
        color: $black;
        padding-left: 20px !important;
        background-image: url('../img/key.svg');
        background-repeat: no-repeat;
        -webkit-background-size: 15px 15px;
        background-size: 15px 15px;
        background-position: left center;
        font-weight: 600;
        @media (max-width: 640px) {
          margin-left: 0;
        }
        a {
          color: $black;
          font-weight: 600;

        }
      }
    }

    .title {
      margin: 0 0 10px;
      background-image: url('../img/covid.svg');
      background-repeat: no-repeat;
      background-position: left center;
      -webkit-background-size: 20px 20px;
      background-size: 20px 20px;
      color: $text;
      font-size: 20px;
      line-height: 1.1em;
      @media (max-width: 1024px) {
        padding-left: 30px !important;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }

  & + .info-link {
    text-align: center;

    a {
      border: 1px solid $primary-shade;
      background-color: $primary-shade;
      color: $white;
      text-transform: uppercase;
      padding: 8px 25px;
      display: inline-block;
      font-size: 16px;
      border-radius: 25px;
      transition: all 0.5s ease-in-out;

      &:hover {
        background-color: $white;
        color: $primary-shade;
      }
    }
  }
}


//Anchors

.anchors_reactiva {
  @media (max-width: 640px) {
    overflow-x:auto;
  }
 > .content{
   display: flex;
   justify-content: space-between;
   margin: 30px 0 0;
   padding-left: 30px;
   box-sizing: border-box;
   @media (max-width: 1024px) {
     padding-left: 0;
     justify-content: start;
   }
   @media (max-width: 640px) {
     width:max-content;
   }
   a {
     padding: 6px 15px;
     border: 1px solid #d8d8d8;
     transition: all 0.5s ease-in-out;
     color: $secondary-shade;
     text-transform: uppercase;
     text-align: center;
     @media (max-width: 1024px) {
       margin-right: 20px;
     }

     &:hover {
       background-color: $bg;
     }
   }
 }
}


//Faqs

.node-type-reactiva {
  a{
    -ms-word-break: break-word;
    word-break: break-word;
  }
  div[id*="block-views-faq"] {
    margin-top: 40px;
    padding-top: 30px;
    border-top: 1px solid #d8d8d8;

    h2.title {
      margin: 0 0 20px;
      padding-left: 0 !important;
      text-transform: uppercase;
      font-size: 21px;
      @media (max-width: 640px) {
        font-size: 20px;
      }
    }
  }
  .view-faq{
    @media (max-width: 1024px) {
      display: block;
    }
  }
}

.view-faq {
  .views-row {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #d8d8d8;;

    .views-field-title-field {
      display: inline-block;
      padding-right: 35px;
      background-image: url("../img/faq/arrow-bottom.svg");
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: right;
      color: $secondary-shade;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      @media (max-width: 640px) {
        display: block;
      }

      &.open {
        background-image: url("../img/faq/arrow-top.svg");
      }
    }

    .views-field-body {
      display: none;

      p {
        padding-left: 0 !important;
      }
    }
  }
}

//Slider home

.views-field-title-field {
  .color_reactiva {
    margin-bottom: 20px;
    font-weight: 800;
    color: $white;
    position: relative;
    background-image: url("../img/covid.svg");
    background-repeat: no-repeat;
    background-position: left center;
    display: inline-block;
    padding-left: 110px;
    background-size: 80px 80px;
    @media (max-width: 1620px) {
      padding-left: 75px;
      -webkit-background-size: 60px;
      background-size: 60px;
    }
    @media (max-width: 1024px) {
      background-image: none;
      padding-left: 0;
      border-bottom: 4px solid $primary-shade;
      margin-bottom: 0;
    }

    &:after {
      content: "";
      width: 285px;
      height: 4px;
      display: block;
      background-color: $primary-shade;
      position: absolute;
      right: -5px;
      bottom: -3px;
      @media (max-width: 1024px) {
        content: none;
      }
    }
  }
}

.views-field-body {
  .color_reactiva {
    max-width: 95%;
    margin: 0 auto;
    @media (min-width: 1024px) {
      max-width: 100%;
      font-size: 16px;
    }
  }
}


.mmenu-nav.mm-basic {
  > .mm-list {
    > .mmenu-mm-list-level-1.mm-list {
      @media (max-width: 1024px) {
        padding-bottom: 65px;
      }
    }
  }
}

h1.title{
  @media (max-width: 640px) {
    margin: 0 0 15px;
  }
}