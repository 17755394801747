//VARIABLES

//colors

$primary-shade: #cd5357;
$secondary-shade: #354050;
$black: #000000;
$white: #FFFFFF;
$red: #ab2a3e;
$blue: #035158;
$bg: #F2F2F2;
$text: #5c6e78;